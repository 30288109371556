import React from 'react'
import { TablePagination } from '@material-ui/core'
import { Pager } from './types'
import { useTranslation } from 'react-i18next'

const rowsPerPageOptions = [5, 10, 20, 50, 100]

type PaginationProps = {} & Pager

export function Pagination(props: PaginationProps) {
  const { t } = useTranslation()

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    props.rowsPerPage || rowsPerPageOptions[0]
  )

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.handleChangeRowsPerPage) {
      setRowsPerPage(parseInt(event.target.value))
      props.handleChangeRowsPerPage(event)
    }
  }

  return (
    <>
      {/**@ts-ignore */}
      <TablePagination
        labelDisplayedRows={({ from, to, count }) => {
          return `${from} - ${to} ${t('de')} ${count}`
        }}
        nextIconButtonText={t('nextPage')}
        backIconButtonText={t('previousPage')}
        labelRowsPerPage={t('rowsPage')}
        count={props.count}
        onChangePage={props.handleChangePage}
        page={props.page}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </>
  )
}
