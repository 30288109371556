import { RouteProps } from '../../../routes/AppRouter'
import { Product } from '../../../modules/product/models/Product'
import { useEffect, useState } from 'react'
import { useStyles } from '../Application.styles'
import { COLOR_PRIMARY } from '../../../routes/color-constants'
import { useTranslation } from 'react-i18next'
import {
  emptyPatientPGTDTO,
  fromPatientPGTModel,
  PatientPGTDTO,
} from '../../../modules/patient/models/patientForm/PatientPGTDTO'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { PatientPGT } from '../../../modules/patient/models/patientForm/PatientPGT'
import { FormType } from '../../../common/enums/Enums'
import { getPatientContainer } from 'container/patient-modules'
import { getClientContainer } from 'container/client-modules'
import { ClientService } from 'modules/clients/services/ClientService'
import { CLIENTS_SERVICE_KEY } from 'modules/clients'
import { PATIENT_SERVICE_KEY } from 'modules/patient'
import { PatientService } from 'modules/patient/services/PatientService'
import { Query, QueryParam } from 'common/api/Query'
import { emptySampleNGSDTO, toModel } from 'modules/sampleType/models/sampleForm/SampleNGSDTO'
import { emptySampleDTO } from 'modules/sample/models/SampleDTO'
import { v4 as uuidv4 } from 'uuid'
import { format, isValid } from 'date-fns'

export type FormPGTProps = RouteProps & {
  patients: PatientPGTDTO[]
  productIndex: number
  handleChangePatients: (productIndex: number, patients: any[], type: FormType) => void
  product: Product
  type?: string
  edit?: boolean
  clientIDDoingTheRequest: string
}

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)

export const PatientFormPGT = (props: FormPGTProps) => {
  const patientNumber = props.product.patientNumber || 0
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t, i18n } = useTranslation()
  const [patients, setPatients] = useState<PatientPGTDTO[]>(props.patients)
  const [previousPatients, setPreviousPatients] = useState<PatientPGT[]>([])
  const [selectedPatientID, setSelectedPatientID] = useState<string[]>([])

  useEffect(() => {
    let patientsAux: PatientPGTDTO[] = []
    let start = 0

    for (let i = start; i < patientNumber; i++) {
      if (props.patients[i]) {
          let filtered = previousPatients.filter((p)=> p.id == props.patients[i]._id)
          let auxSelected: any[] = []

          if (filtered?.length > 0) {
            if (patientNumber > 0) {
              auxSelected[0] = filtered[0].id
              patientsAux[0] = fromPatientPGTModel(filtered[0])
            }
    
            setSelectedPatientID(auxSelected)
          }
          patientsAux[i] = props.patients[i]
      } else {
        patientsAux[i] = emptyPatientPGTDTO()
      }
    }

    setPatients(patientsAux)
  }, [previousPatients])

  useEffect(() => {
    if (props.patients?.length > 0) {
      let auxSelected: any[] = []
      props.patients.forEach((p, i) => {
        auxSelected[i] = p._id
      })
      setSelectedPatientID(auxSelected)
    } else {
      let auxSelected: any[] = []
      Array(patientNumber).forEach((_, i) => {
        auxSelected[i] = ''
      })
      setSelectedPatientID(auxSelected)
    }
  }, [])

  useEffect(() => {
    clientService.getByID(props.clientIDDoingTheRequest).subscribe(async (res) => {
      if (res) {
        let client = res

        let patients = await patientService
          .getFilteredList(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let citogenicPatientsItemList = await patientService
          .getFilteredListCitogenicArray(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let microvePatients = await patientService
          .getFilteredListMicroVE(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let ngsPatients = await patientService
          .getFilteredListNGS(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let oncologyPatients = await patientService
          .getFilteredListOncology(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let pgtPatientsList = await patientService
          .getFilteredListPGT(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let pgtPatients: any[] = pgtPatientsList?.items || []

        pgtPatients.forEach((i) => {
          i.priority = true
        })

        const resultArray = [
          ...(patients?.items || []),
          ...(citogenicPatientsItemList?.items || []),
          ...(microvePatients?.items || []),
          ...(ngsPatients?.items || []),
          ...(oncologyPatients?.items || []),
          ...pgtPatients,
        ]

        let personasFiltradas: any[] = filtrarPersonas(resultArray)

        if (window.location.pathname.includes('new')) {
          personasFiltradas.forEach((persona) => {
            //@ts-ignore
            persona._sample = toModel(emptySampleDTO())
            //@ts-ignore
            persona.sample = toModel(emptySampleDTO())
          })
        }

        setPreviousPatients(personasFiltradas)
      }
    })
  }, [])

  function filtrarPersonas(personas: any[]): any[] {
    const personasFiltradas: { [key: string]: any } = {}

    personas.forEach((persona) => {
      if (personasFiltradas[persona.firstName + persona.lastName]) {
        if (persona.priority) {
          personasFiltradas[persona.firstName + persona.lastName] = persona
        }
      } else {
        personasFiltradas[persona.firstName + persona.lastName] = persona
      }
    })

    return Object.values(personasFiltradas)
  }

  useEffect(() => {
    let auxSelected: any[] = []
    Array(patientNumber).forEach((_, i) => {
      auxSelected[i] = ''
    })
    setSelectedPatientID(auxSelected)
  }, [])

  const getEmptyPatients = (): PatientPGTDTO[] => {
    const patients: PatientPGTDTO[] = []
    if (patientNumber) {
      for (let i = 0; i < patientNumber; i++) {
        patients.push(emptyPatientPGTDTO())
      }
    }
    return patients
  }

  useEffect(() => {
    if (
      props.type != 'view' &&
      ((props.patients !== null && props.patients?.length == 0) || props.patients == null)
    ) {
      props.handleChangePatients(props.productIndex, getEmptyPatients(), FormType.TypeFormPGT)
    }
  }, [props.product])

  useEffect(() => {
    if (props.type == 'view') {
      props.handleChangePatients(props.productIndex, props.patients, FormType.TypeFormPGT)
    }
  }, [props.type])

  const handlePatientChange = (
    index: number,
    field: keyof PatientPGT,
    value: string | Date | boolean
  ) => {
    const newPatients = [...patients]
    const patientToUpdate = newPatients[index]
    const updatedPatient = { ...patientToUpdate, [field]: value }

    if (!updatedPatient._id) {
      updatedPatient._id = uuidv4()
    }

    newPatients[index] = updatedPatient

    setPatients(newPatients)
    props.handleChangePatients(props.productIndex, newPatients, FormType.TypeFormPGT)
  }

  const handleSetSelectedValue = (index: number, value: string) => {
    let patientsAux = previousPatients.filter((p) => p.id == value)
    const newPatients = [...patients]

    if (patientsAux?.length > 0) {
      let patient = patientsAux[0]

      let auxElement = {
        _id: patient.id,
        _firstName: patient.firstName,
        _lastName: patient.lastName,
        _dob: patient.dob,
        _nhcOrDni: patient.nhcOrDni,
        _karyotype: patient.karyotype,
        _indication: patient.indication,
        _clinicHistory: patient.clinicHistory,
        _sample: patient._sample ? patient._sample : toModel(emptySampleNGSDTO()),
      }
      newPatients.splice(index, 1, auxElement)
    } else {
      newPatients.splice(index, 1, emptyPatientPGTDTO())
    }

    setPatients(newPatients)
    props.handleChangePatients(props.productIndex, newPatients, FormType.TypeFormPGT)

    let auxSelected = [...selectedPatientID]
    auxSelected[index] = value
    setSelectedPatientID(auxSelected)
  }

  const formatDate = (date: Date) =>{
    const sdate: string = format(date, "ddMMyyyy")
    const d = parseInt(sdate.slice(0,2))
    const m = parseInt(sdate.slice(2,4))-1
    const y = parseInt(sdate.slice(4,8))
    
    return new Date (Date.UTC(y,m,d))
  }

  return (
    <Grid container direction="column">
      {[...Array(patientNumber)].map((_, index) => (
        <Grid container spacing={4} key={index} style={{ marginTop: '40px' }}>
          <Grid item xs={12} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '18px' }}>
          {t('fillPatientData') + ' ' + (index + 1) + ' ' + t('for') + ' ' + (i18n.language == "es" ? props.product.nameES : props.product.nameEN)}
          </Grid>
          {props.edit !== false && (
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="typeSampleLabel">{t('selectPatient')}</InputLabel>
                <Select
                  labelId={'typeSampleLabel'}
                  id={'type'}
                  name={'type'}
                  //@ts-ignore
                  onChange={(e) => handleSetSelectedValue(index, e.target.value)}
                  style={{ width: '50%' }}
                  value={selectedPatientID?.length > index ? selectedPatientID[index] : ''}>
                  {previousPatients.map((patient, indexST) => {
                    return (
                      <MenuItem key={indexST} value={patient.id}>
                        {patient.firstName + ' ' + patient.lastName}
                      </MenuItem>
                    )
                  })}
                  <MenuItem key={''} value={''}>
                    {t('notSelect')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              id={`firstName-${index}`}
              label={t('name')}
              variant="filled"
              fullWidth
              disabled={props.edit === false}
              className={classes.textField}
              value={(patients && patients[index]?._firstName) || ''}
              onChange={(e) => handlePatientChange(index, '_firstName', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`lastName-${index}`}
              label={t('lastName')}
              variant="filled"
              disabled={props.edit === false}
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._lastName) || ''}
              required
              onChange={(e) => handlePatientChange(index, '_lastName', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                id={`dob-${index}`}
                autoOk
                inputVariant={'outlined'}
                format="dd/MM/yyyy"
                value={(patients && patients[index]?._dob) || null}
                onChange={(date) => date && isValid(date) && handlePatientChange(index, '_dob', formatDate(date))}
                size={'small'}
                label={t('dob')}
                disabled={props.edit === false}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`nhc-${index}`}
              label={t('nhc')}
              disabled={props.edit === false}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._nhcOrDni) || ''}
              required
              onChange={(e) => handlePatientChange(index, '_nhcOrDni', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id={`indication-${index}`}
              disabled={props.edit === false}
              label={t('indication')}
              onChange={(event) => handlePatientChange(index, '_indication', event.target.value)}
              multiline
              rows={4}
              value={(patients && patients[index]?._indication) || ''}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id={`clinicHistory-${index}`}
              disabled={props.edit === false}
              label={t('clinicHistory')}
              onChange={(event) => handlePatientChange(index, '_clinicHistory', event.target.value)}
              multiline
              rows={4}
              value={(patients && patients[index]?._clinicHistory) || ''}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch />}
              label={patients && t(patients[index]?._karyotype ? 'karyotypeYes' : 'karyotypeNo')}
              checked={patients && patients[index]?._karyotype}
              disabled={props.edit === false}
              onChange={(e) =>
                handlePatientChange(
                  index,
                  '_karyotype',
                  (e.target as unknown as HTMLInputElement).checked
                )
              }
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
