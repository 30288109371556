import { RouteProps } from '../../../routes/AppRouter'
import { PatientMicroVE } from '../../../modules/patient/models/patientForm/PatientMicroVE'
import { Product } from '../../../modules/product/models/Product'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../Application.styles'
import { COLOR_PRIMARY } from '../../../routes/color-constants'
import {
  emptyPatientMicroVEDTO,
  fromPatientMicroVEModel,
  PatientMicroVEDTO,
} from '../../../modules/patient/models/patientForm/PatientMicroVEDTO'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { FormType } from 'common/enums/Enums'
import { getPatientContainer } from 'container/patient-modules'
import { getClientContainer } from 'container/client-modules'
import { PATIENT_SERVICE_KEY } from 'modules/patient'
import { CLIENTS_SERVICE_KEY } from 'modules/clients'
import { PatientService } from 'modules/patient/services/PatientService'
import { ClientService } from 'modules/clients/services/ClientService'
import { Query, QueryParam } from 'common/api/Query'
import {
  emptySampleMicroVEBioERDTO,
  toModel,
} from 'modules/sampleType/models/sampleForm/SampleMicroVEBioERDTO'
import { emptySampleDTO } from 'modules/sample/models/SampleDTO'
import { v4 as uuidv4 } from 'uuid'
import { format, isValid } from 'date-fns'

export type FormMicroProps = RouteProps & {
  patients: PatientMicroVEDTO[]
  productIndex: number
  handleChangePatients: (productIndex: number, patients: any[], type: FormType) => void
  product: Product
  type?: string
  edit?: boolean
  clientIDDoingTheRequest: string
}

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)

export const PatientFormMicroVEBioER = (props: FormMicroProps) => {
  const patientNumber = props.product.patientNumber || 0
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t, i18n } = useTranslation()
  const [patients, setPatients] = useState<any[]>(props.patients)
  const [previousPatients, setPreviousPatients] = useState<PatientMicroVE[]>([])
  const [selectedPatientID, setSelectedPatientID] = useState<string[]>([])

  useEffect(() => {
    let patientsAux: PatientMicroVEDTO[] = []
    let start = 0

    for (let i = start; i < patientNumber; i++) {
      if (props.patients[i]) {
        let filtered = previousPatients.filter((p) => p.id == props.patients[i]._id)
        let auxSelected: any[] = []

        if (filtered?.length > 0) {
          if (patientNumber > 0) {
            auxSelected[0] = filtered[0].id
            patientsAux[0] = fromPatientMicroVEModel(filtered[0])
          }

          setSelectedPatientID(auxSelected)
        }
        patientsAux[i] = props.patients[i]
      } else {
        patientsAux[i] = emptyPatientMicroVEDTO()
      }
    }

    setPatients(patientsAux)
  }, [previousPatients])

  useEffect(() => {
    if (props.patients?.length > 0) {
      let auxSelected: any[] = []
      props.patients.forEach((p, i) => {
        auxSelected[i] = p._id
      })
      setSelectedPatientID(auxSelected)
    } else {
      let auxSelected: any[] = []
      Array(patientNumber).forEach((_, i) => {
        auxSelected[i] = ''
      })
      setSelectedPatientID(auxSelected)
    }
  }, [])

  useEffect(() => {
    clientService.getByID(props.clientIDDoingTheRequest).subscribe(async (res) => {
      if (res) {
        let client = res
        let patients = await patientService
          .getFilteredList(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let citogenicPatientsItemList = await patientService
          .getFilteredListCitogenicArray(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let microvePatientsItemlist = await patientService
          .getFilteredListMicroVE(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let microvePatients: any[] = microvePatientsItemlist?.items || []

        microvePatients.forEach((i) => {
          i.priority = true
        })

        let ngsPatients = await patientService
          .getFilteredListNGS(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let oncologyPatients = await patientService
          .getFilteredListOncology(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let pgtPatients = await patientService
          .getFilteredListPGT(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        const resultArray = [
          ...(patients?.items || []),
          ...(citogenicPatientsItemList?.items || []),
          ...microvePatients,
          ...(ngsPatients?.items || []),
          ...(oncologyPatients?.items || []),
          ...(pgtPatients?.items || []),
        ]

        let personasFiltradas: any[] = filtrarPersonas(resultArray)
        if (window.location.pathname.includes('new')) {
          personasFiltradas.forEach((persona) => {
            //@ts-ignore
            persona._sample = toModel(emptySampleDTO())
            //@ts-ignore
            persona.sample = toModel(emptySampleDTO())
          })
        }

        setPreviousPatients(personasFiltradas)
      }
    })
  }, [])

  function filtrarPersonas(personas: any[]): any[] {
    const personasFiltradas: { [key: string]: any } = {}

    personas.forEach((persona) => {
      if (personasFiltradas[persona.firstName + persona.lastName]) {
        if (persona.priority) {
          personasFiltradas[persona.firstName + persona.lastName] = persona
        }
      } else {
        personasFiltradas[persona.firstName + persona.lastName] = persona
      }
    })

    return Object.values(personasFiltradas)
  }

  const getEmptyPatients = (): PatientMicroVEDTO[] => {
    const patients: PatientMicroVEDTO[] = []
    if (patientNumber) {
      for (let i = 0; i < patientNumber; i++) {
        patients.push(emptyPatientMicroVEDTO())
      }
    }
    return patients
  }

  useEffect(() => {
    if (
      props.type != 'view' &&
      ((props.patients !== null && props.patients?.length == 0) || props.patients == null)
    ) {
      props.handleChangePatients(
        props.productIndex,
        getEmptyPatients(),
        FormType.TypeFormMicroVEBioER
      )
    }
  }, [props.product])

  useEffect(() => {
    if (props.type == 'view') {
      props.handleChangePatients(props.productIndex, props.patients, FormType.TypeFormMicroVEBioER)
    }
  }, [props.type])

  const age = (dob: any) => {
    const today = new Date()
    const month = today.getMonth() - dob.getMonth()
    const year = today.getFullYear() - dob.getFullYear()

    return month < 0 || (month === 0 && today.getDate() < dob.getDate()) ? year - 1 : year
  }
  const handlePatientChange = (
    index: number,
    field: keyof PatientMicroVE,
    value: string | number | Date
  ) => {
    const newPatients = [...patients]
    const patientToUpdate = newPatients[index]
    let updatedPatient = { ...patientToUpdate, [field]: value }
    if (field === '_dob') {
      updatedPatient = { ...patientToUpdate, ['_age']: age(value), [field]: value }
    }

    if (!updatedPatient._id) {
      updatedPatient._id = uuidv4()
    }
    newPatients[index] = updatedPatient

    setPatients(newPatients)
    props.handleChangePatients(props.productIndex, newPatients, FormType.TypeFormMicroVEBioER)
  }

  const handleChangeGynecological = (type: string, index: number) => {
    if (type == '_abortion') {
      if (patients?.length >= index) {
        if (patients[index]?._abortions > 0) {
          handlePatientChange(index, '_abortions', 0)
        } else {
          handlePatientChange(index, '_abortions', 1)
        }
      }
    } else if (type == '_fiv') {
      if (patients?.length >= index) {
        if (patients[index]?._ivfFailure > 0) {
          handlePatientChange(index, '_ivfFailure', 0)
        } else {
          handlePatientChange(index, '_ivfFailure', 1)
        }
      }
    }
  }

  const handleSetSelectedValue = (index: number, value: string) => {
    let patientsAux = previousPatients.filter((p) => p.id == value)
    const newPatients: any[] = [...patients]

    if (patientsAux?.length >= 0) {
      let patient = patientsAux[0]
      let auxElement = {
        _id: patient.id,
        _firstName: patient.firstName,
        _lastName: patient.lastName,
        _age: patient.age,
        _dob: patient.dob,
        _phone: patient.phone,
        _email: patient.email,
        _ivfFailure: patient.ivfFailure,
        _abortions: patient.abortions,
        _nhc: patient.nhc,
        _sample: patient._sample ? patient._sample : toModel(emptySampleMicroVEBioERDTO()),
      }
      newPatients.splice(index, 1, auxElement)
    } else {
      newPatients.splice(index, 1, emptyPatientMicroVEDTO())
    }

    setPatients(newPatients)
    props.handleChangePatients(props.productIndex, newPatients, FormType.TypeFormMicroVEBioER)

    let auxSelected = [...selectedPatientID]
    auxSelected[index] = value
    setSelectedPatientID(auxSelected)
  }
  
  const formatDate = (date: Date) =>{
    const sdate: string = format(date, "ddMMyyyy")
    const d = parseInt(sdate.slice(0,2))
    const m = parseInt(sdate.slice(2,4))-1
    const y = parseInt(sdate.slice(4,8))
    
    return new Date (Date.UTC(y,m,d))
  }
  return (
    <Grid container direction="column">
      {[...Array(patientNumber)].map((_, index) => (
        <Grid container spacing={4} key={index} style={{ marginTop: '40px' }}>
          <Grid item xs={12} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '18px' }}>
            {t('fillPatientData') +
              ' ' +
              (index + 1) +
              ' ' +
              t('for') +
              ' ' +
              (i18n.language == 'es' ? props.product.nameES : props.product.nameEN)}
          </Grid>
          {props.edit !== false && (
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="typeSampleLabel">{t('selectPatient')}</InputLabel>
                <Select
                  labelId={'typeSampleLabel'}
                  id={'type'}
                  name={'type'}
                  //@ts-ignore
                  onChange={(e) => handleSetSelectedValue(index, e.target.value)}
                  style={{ width: '50%' }}
                  value={selectedPatientID?.length > index ? selectedPatientID[index] : ''}>
                  {previousPatients.map((patient, indexST) => {
                    return (
                      <MenuItem key={indexST} value={patient.id}>
                        {patient.firstName + ' ' + patient.lastName}
                      </MenuItem>
                    )
                  })}
                  <MenuItem key={''} value={''}>
                    {t('notSelect')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              id={`firstName-${index}`}
              label={t('name')}
              variant="filled"
              disabled={props.edit === false}
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._firstName) || ''}
              required
              onChange={(e) => handlePatientChange(index, '_firstName', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`lastName-${index}`}
              label={t('lastName')}
              disabled={props.edit === false}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._lastName) || ''}
              required
              onChange={(e) => handlePatientChange(index, '_lastName', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                id={`dob-${index}`}
                autoOk
                disabled={props.edit === false}
                inputVariant={'outlined'}
                format="dd/MM/yyyy"
                value={(patients && new Date(patients[index]?._dob)) || null}
                onChange={(date) => date && isValid(date) && handlePatientChange(index, '_dob', formatDate(date))}
                size={'small'}
                InputLabelProps={{ shrink: true }}
                label={t('dob')}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`phone-${index}`}
              label={t('phone')}
              variant="filled"
              disabled={props.edit === false}
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._phone) || ''}
              onChange={(e) => handlePatientChange(index, '_phone', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`email-${index}`}
              label={t('email')}
              variant="filled"
              disabled={props.edit === false}
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._email) || ''}
              onChange={(e) => handlePatientChange(index, '_email', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`nhc-${index}`}
              label={t('nhc')}
              variant="filled"
              disabled={props.edit === false}
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._nhc) || ''}
              required
              onChange={(e) => handlePatientChange(index, '_nhc', e.target.value)}
            />
          </Grid>
          <Grid container spacing={4} key={1} className={classes.gynecologicalConditionGrid}>
            <Grid item xs={12} className={classes.gynecologicalConditionText}>
              {t('gynecologicalCondition')}
            </Grid>

            <Grid item xs={8} className={classes.grid}>
              <Checkbox
                checked={patients[index]?._ivfFailure > 0}
                onChange={() => handleChangeGynecological('_fiv', index)}
                className={classes.padding1}
                color="primary"
                disabled={props.edit === false}
              />
              {t('fivFail')}: {t('ciclesNumber')}:
            </Grid>
            <Grid item xs={4} className={classes.grid2}>
              <TextField
                id="code"
                value={(patients && patients[index]?._ivfFailure) || 0}
                onChange={(e) =>
                  handlePatientChange(index, '_ivfFailure', parseFloat(e.target.value))
                }
                type="number"
                className={classes.width40}
                inputProps={{ min: 0 }}
                disabled={props.edit === false}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={4}
            key={1}
            className={classes.miscarriagesGrid}
            style={{ marginBottom: '1%' }}>
            <Grid item xs={8} className={classes.grid}>
              <Checkbox
                checked={patients[index]?._abortions > 0}
                onChange={() => handleChangeGynecological('_abortion', index)}
                className={classes.padding1}
                color="primary"
                disabled={props.edit === false}
              />
              {t('miscarriages')}: {t('miscarriagesNumber')}:
            </Grid>

            <Grid item xs={4} className={classes.grid2}>
              <TextField
                id={`abortions-${index}`}
                value={(patients && patients[index]?._abortions) || 0}
                onChange={(e) =>
                  handlePatientChange(index, '_abortions', parseFloat(e.target.value))
                }
                type="number"
                className={classes.width40}
                inputProps={{ min: 0 }}
                disabled={props.edit === false}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
