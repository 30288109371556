import React, { useState, useEffect } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
} from '@material-ui/core'
import { Modal } from '@mui/material'
import { Actions, Field } from './types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { GenericTooltip } from '../generic-tooltip'
import { downloadFile } from 'common/files/file'
import { getFileContainer } from 'container/file-module'
import { getRequestContainer } from 'container/request-modules'
import { FileService } from 'modules/files/services/FileService'
import { RequestService } from 'modules/request/services/RequestService'
import { REQUEST_SERVICE_KEY } from 'modules/request'
import { FILE_SERVICE_KEY } from 'modules/files'
import { getAuthContainer } from 'container/auth-modules'
import { AuthService } from 'modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from 'modules/auth'
import { CLIENT2REQUEST_SERVICE_KEY } from 'modules/client2Requests'
import { getClient2RequestContainer } from 'container/client2request-modules'
import { Client2RequestService } from 'modules/client2Requests/services/Client2RequestService'
import { Client, ClientQuery } from 'modules/clients/models/Client'
import { getClientContainer } from 'container/client-modules'
import { ClientService } from 'modules/clients/services/ClientService'
import { CLIENTS_SERVICE_KEY } from 'modules/clients'
import { Query, QueryParam } from 'common/api/Query'

export type BodyProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  readonly areRequests?: boolean
  handleCheck?: (item: any) => void
  requestsSendNacex?: any[]
  readonly allChecked?: boolean
}

const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const requestService = getRequestContainer().get<RequestService>(REQUEST_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const client2RequestService = getClient2RequestContainer().get<Client2RequestService>(
  CLIENT2REQUEST_SERVICE_KEY
)
const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)

export function Body<T extends { [key: string]: any }>(props: BodyProps<T>) {
  const { t } = useTranslation()
  const [selectedValue, setSelectedValue] = useState<any>('')
  const loggedUser = authService.get()
  const [itemtoreq, setItemToReq] = useState<any[]>([])
  const [client, setClient] = useState<Client>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showNotAttached, setShowNotAttached] = useState<boolean>(false)

  useEffect(() => {
    if (loggedUser) {
      clientService
        .getFilteredList(
          new Query({
            query: [new QueryParam<ClientQuery>('ID', loggedUser.clinicID)],
          })
        )
        .subscribe((res) => {
          if (res && res.items.length > 0) {
            setClient(res.items[0])
          }
        })
    }
  }, [loggedUser])

  const useStyles = makeStyles((_) => ({
    body: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'poppins',
      borderBottom: '1px solid #D1D1D1',
      padding: '0 auto 0 auto',
    },
    icon: {
      verticalAlign: 'middle',
      cursor: 'pointer',
      paddingTop: '0',
      paddingBottom: '0',
    },
    modalLoading: {
      position: 'absolute',
      fontSize: '35px',
      backgroundColor: 'white',
      top: '50%',
      left: '50%',
      padding: '30px',
      borderRadius: '10%',
      display: 'flex',
      justifyContent: 'center',
    },
    notAvailable: {
      position: 'absolute',
      backgroundColor: 'white',
      padding: '30px',
      borderRadius: '30px',
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '300px',
      top: '40%',
      left: '45%',
    },
    circularProgress: {
      alignSelf: 'center',
    },
  }))

  useEffect(() => {
    if (props.actions?.items?.map((i) => i.label).includes(t('downloads'))) {
      client2RequestService.getByUserID(loggedUser.id).subscribe((res) => {
        setItemToReq(res.items)
      })
    }
  }, [])

  const classes = useStyles()

  const isChecked = (id: string) => {
    let items = props.requestsSendNacex?.filter((item) => item.id === id)

    if (items?.length === 0) {
      return false
    } else {
      return items && items[0].checked
    }
  }

  const handlePressDownload = (value: any, id: string) => {
    switch (value) {
      case t('downloadAttachedDocumentation'):
        setIsLoading(true)
        requestService.downloadAttached(id).subscribe((f) => {
          f && downloadFile(f.name, f.mimeType, f.base64)
          setIsLoading(false)
        })
        break
      case t('downloadResult'):
        requestService.downloadResults(id).subscribe((f) => {
          f && downloadFile(f.name, f.mimeType, f.base64)
        })
        break
      case t('downloadResume'):
        fileService.downloadReport(id).subscribe((res) => {
          if (res == undefined) {
            setShowNotAttached(true)
          } else {
            res && downloadFile(res.name, res.mimeType, res.base64)
            setShowNotAttached(false)
          }
        })
        break
    }
    setSelectedValue(value)
  }

  const hasResults = (requestID: string) => {
    let item = itemtoreq.filter((i) => i.request.id == requestID)
    return item.length > 0 && item[0].request.hasResultReport
  }

  const rows = props.items?.map((item) => (
    <TableRow key={item[props.rowKeyField]}>
      {props.areRequests && item.client.country == 'spain' && client?.country == 'spain' ? (
        <TableCell key="">
          <input
            type="checkbox"
            checked={isChecked(item.request.id) && item.request.trackingState == 'ERROR'}
            onChange={() => props.handleCheck && props.handleCheck(item)}
            disabled={item.request.trackingState !== 'ERROR'}
          />
        </TableCell>
      ) : (
        <>{client?.country == 'spain' && props.areRequests && <TableCell key="" />}</>
      )}
      {Object.values(props.fields).map((field) => (
        <TableCell key={field.name as string}>
          {field.renderFunc
            ? field.renderFunc(field, item)
            : item[field.name] && item[field.name].toString()}
        </TableCell>
      ))}

      {props.actions && props.actions.items?.length > 0 && (
        <TableCell key="actions-table">
          {/**@ts-ignore */}
          <Box display="flex" justifyContent="space-between" style={{ alignItems: 'center' }}>
            {props.actions.items?.map((a) => {
              if (a.hidden && a.hidden(item)) {
                return <></>
              } else if (a.label == t('downloads')) {
                return (
                  <TableCell key="actions-table" style={{ padding: 0, width: '120px' }}>
                    {/**@ts-ignore */}
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="typeSampleLabel">{t('downloads')}</InputLabel>
                      <Select
                        labelId="typeSampleLabel"
                        id="demo-simple-select"
                        value={selectedValue}
                        onChange={(e) => handlePressDownload(e.target.value, item.id)}>
                        <MenuItem value={t('downloadAttachedDocumentation')}>
                          {t('downloadAttachedDocumentation')}
                        </MenuItem>
                        {hasResults(item.id) && (
                          <MenuItem value={t('downloadResult')}>{t('downloadResult')}</MenuItem>
                        )}
                        {item.status != 'Eraser' && (
                          <MenuItem value={t('downloadResume')}>{t('downloadResume')}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>
                )
              }
              return (
                <GenericTooltip
                  values={[t(a.label || a.icon)]}
                  icon={
                    <img
                      src={a.icon}
                      className={classes.icon}
                      key={item[props.rowKeyField]}
                      onClick={() => a.handler(item)}
                    />
                  }
                  noUseFab={true}
                />
              )
            })}
          </Box>
        </TableCell>
      )}
    </TableRow>
  ))

  return (
    <TableBody>
      {rows}
      <Modal open={isLoading}>
        <div className={classes.modalLoading}>
          <CircularProgress className={classes.circularProgress} />
        </div>
      </Modal>
      <Modal open={showNotAttached} onClose={() => setShowNotAttached(!showNotAttached)}>
        <div className={classes.notAvailable}>
          <p style={{ fontSize: '15px' }}>{t('notAvailableResume')}</p>
        </div>
      </Modal>
    </TableBody>
  )
}
