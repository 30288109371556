export type ProductProps = {
  id: string | undefined
  nameES: string
  nameEN: string
  type: string
  reference: string
  price: number
  patientNumber: number
  samplePerPatient: boolean
  genes: string
  idOdoo:  string,
  typeForm: string,
  typeConsent: string
}

export class Product {
  private readonly _id: string | undefined
  private _nameES: string
  private _nameEN: string
  private _type: string
  private _reference: string
  private _price: number
  private _patientNumber: number
  private _samplePerPatient: boolean
  _genes: string
  private _idOdoo: string
  private _typeForm: string
  private _typeConsent: string

  constructor(p: ProductProps) {
    this._id = p.id
    this._nameES = p.nameES
    this._type = p.type
    this._reference = p.reference
    this._price = p.price
    this._patientNumber = p.patientNumber
    this._samplePerPatient = p.samplePerPatient
    this._genes = p.genes
    this._idOdoo = p.idOdoo
    this._typeConsent = p.typeConsent
    this._typeForm = p.typeForm
    this._nameEN = p.nameEN
  }

  get id(): string | undefined {
    return this._id
  }

  get nameES(): string  {
    return this._nameES
  }

  set nameES(value: string ) {
    this._nameES = value
  }

  get nameEN(): string  {
    return this._nameEN
  }

  set nameEN(nameEN: string ) {
    this._nameEN = nameEN
  }

  get type(): string {
    return this._type
  }

  set type(value: string) {
    this._type = value
  }

  get reference(): string {
    return this._reference
  }

  set reference(value: string) {
    this._reference = value
  }

  get price(): number {
    return this._price
  }

  set price(value: number) {
    this._price = value
  }

  get patientNumber(): number {
    return this._patientNumber
  }

  set patientNumber(value: number) {
    this._patientNumber = value
  }

  get samplePerPatient(): boolean {
    return this._samplePerPatient
  }

  set samplePerPatient(value: boolean) {
    this._samplePerPatient = value
  }

  get genes(): string {
    return this._genes
  }

  set genes(value: string) {
    this._genes = value
  }

  get idOdoo(): string {
    return this._idOdoo
  }

  set idOdoo(value: string) {
    this._idOdoo = value
  }

  get typeForm(): string {
    return this._typeForm
  }

  set typeForm(value: string) {
    this._typeForm = value
  }

  get typeConsent(): string {
    return this._typeConsent
  }

  set typeConsent(value: string) {
    this._typeConsent = value
  }

}

export interface ProductQuery {
  id: string
  ids: string[]
  name: string
  type: string
  price: number
}
