import { COLOR_APPLICATIONS } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { RequestQuery } from '../../modules/request/models/Request'
import { navigate } from '@reach/router'
import { URL_APPLICATION_EDIT } from '../../routes/routes-constants'
import seeIcon from '../../assets/table-icons/see-icon.svg'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'
import { getClient2RequestContainer } from '../../container/client2request-modules'
import { Client2RequestService } from '../../modules/client2Requests/services/Client2RequestService'
import { CLIENT2REQUEST_SERVICE_KEY } from '../../modules/client2Requests'
import { Client2Request } from '../../modules/client2Requests/models/Client2Request'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { downloadFile } from '../../common/files/file'
import { getRequestContainer } from 'container/request-modules'
import { RequestService } from 'modules/request/services/RequestService'
import { REQUEST_SERVICE_KEY } from 'modules/request'
import { Button, Modal } from '@material-ui/core'
import { Address } from 'modules/address/models/Address'
import { ModalAddress } from './ModalAddress'
import { useSnackbar } from 'notistack'
import { AddressDTO, emptyAddressDTO } from 'modules/address/models/AddressDTO'
import { v4 as uuidv4 } from 'uuid'
import { Client, ClientQuery } from 'modules/clients/models/Client'
import { getClientContainer } from 'container/client-modules'
import { CLIENTS_SERVICE_KEY } from 'modules/clients'
import { ClientService } from 'modules/clients/services/ClientService'

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const client2RequestService = getClient2RequestContainer().get<Client2RequestService>(
  CLIENT2REQUEST_SERVICE_KEY
)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const requestService = getRequestContainer().get<RequestService>(REQUEST_SERVICE_KEY)
const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)

export const Table = () => {
  const { t } = useTranslation()
  const loggedUser = authService.get()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<Client2Request[]>([])
  const [allItems, setAllItems] = useState<Client2Request[]>([])
  const [address, setAddress] = useState<AddressDTO>(emptyAddressDTO())
  const [sort, setSort] = useState<SortParam<Client2Request>>({
    field: 'date',
    desc: true,
  })
  const [searcher, setSearcher] = useState<SearchValue<RequestQuery>[]>([
    {
      name: 'id',
      label: t('search'),
    },
    {
      name: 'status',
      label: t('status'),
    },
  ])
  const [requestsSendNacex, setRequestsToSendNacex] = useState<any[]>([])
  const [nacexModalOpened, setNacexModalOpened] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [client, setClient] = useState<Client>()
  const [selectedStatus, setSelectedStatus] = useState<string>('')

  useEffect(() => {
    if (!isLoading) {
      return
    }

    let searchs = searcher.filter((sv) => sv.value).map((s) => s.value)

    let search: any = ''
    if (searchs.length > 0) {
      search = searchs[0]
    }

    client2RequestService.byUserIDPostAll(authService.get()?.id, search, selectedStatus).subscribe((res) => {
      setAllItems(res.items)
    })
  }, [isLoading, searcher, selectedStatus])

  useEffect(() => {
    if (!isLoading) {
      return
    }

    let searchs = searcher.filter((sv) => sv.value).map((s) => s.value)

    let search: any = ''
    if (searchs.length > 0) {
      search = searchs[0]
    }

    client2RequestService
      .byUserIDPost(
        authService.get()?.id,
        page * itemsPerPage,
        itemsPerPage,
        search,
        sort.field,
        sort.desc,
        selectedStatus
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
      })
  }, [isLoading, searcher, sort, selectedStatus])

  useEffect(() => {
    if (loggedUser) {
      clientService
        .getFilteredList(
          new Query({
            query: [new QueryParam<ClientQuery>('ID', loggedUser.clinicID)],
          })
        )
        .subscribe((res) => {
          if (res && res.items.length > 0) {
            setClient(res.items[0])
          }
        })
    }
  }, [loggedUser])

  const handleCheckAll = () => {
    if (allChecked) {
      setAllChecked(false)
      setRequestsToSendNacex(
        requestsSendNacex.map((i) => {
          return {
            id: i.id,
            checked: false,
          }
        })
      )
    } else {
      setAllChecked(true)

      if (items?.length > 0) {
        setRequestsToSendNacex(
          allItems
            .filter((i) => i.client.country == 'spain' && client?.country == 'spain')
            ?.map((i) => {
              return {
                id: i.request.id,
                checked: true,
              }
            })
        )

        let item = items[0]
        setAddress({
          //@ts-ignore
          _id: uuidv4(),
          _requestID: item.request.id,
          _street: item.client.street,
          _number: item.client.number,
          _zipCode: item.client.zipCode,
          _city: item.client.city,
          _province: item.client.province,
          _date: new Date(),
          _hour: 0,
          _firstName: client?.taxName,
          _lastName: client?.taxIdentity,
          _phone: loggedUser.phone,
        })
      }
    }
  }

  const handleCheck = (item: Client2Request) => {
    setAddress({
      //@ts-ignore
      _id: uuidv4(),
      _requestID: item.request.id,
      _street: item.client.street,
      _number: item.client.number,
      _zipCode: item.client.zipCode,
      _city: item.client.city,
      _province: item.client.province,
      _date: new Date(),
      _hour: 0,
      _firstName: item.client.taxName,
      _lastName: item.client.taxIdentity,
      _phone: loggedUser.phone,
    })

    const isSame = (aux: any) => aux.id === item.request.id
    let item2 = requestsSendNacex.findIndex(isSame)

    if (item2 !== -1) {
      let aux = [...requestsSendNacex]
      aux[item2].checked = !aux[item2].checked
      setRequestsToSendNacex(aux)
    } else {
      let aux = [...requestsSendNacex]
      aux.push({ id: item.request.id, checked: true })
      setRequestsToSendNacex(aux)
    }
  }

  useEffect(() => {
    setIsLoading(true)

    if (itemsPerPage > count) {
      setPage(0)
    }

    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<any>[] = [
    {
      searchable: true,
      label: t('date'),
      //@ts-ignore
      name: 'date',
      sortable: true,
      renderFunc: (field, item) =>
        new Date(item.request.date)
          .toLocaleString()
          .substring(0, new Date(item.request.date).toLocaleString().length - 3) + ' h',
    },
    {
      searchable: true,
      label: t('client'),
      name: 'client',
      sortable: true,
      renderFunc: (field, item) => item.client.taxName,
    },
    {
      searchable: true,
      label: t('patient'),
      name: 'patient',
      sortable: true,
      renderFunc: (field, item) => item.patient.firstName + ' ' + item.patient.lastName,
    },
    {
      searchable: true,
      sortable: false,
      label: t('codeSample'),
      name: 'request',
      renderFunc: (field, item) => item.request.codeSample,
    },
    {
      searchable: true,
      sortable: false,
      label: t('state'),
      //@ts-ignore
      name: 'state',
      renderFunc: (field, item) => t(item.request.status),
    },
    {
      searchable: true,
      sortable: false,
      label: t('trackingState'),
      //@ts-ignore
      name: 'trackingState',
      renderFunc: (field, item) =>
        item.client.country == 'spain' ? t(item.request.trackingState) : '',
    },
    {
      searchable: true,
      sortable: false,
      label: t('bioarrayCode'),
      //@ts-ignore
      name: 'bioarrayCode',
      renderFunc: (field, item) => item.request.bioarrayCode,
    },
  ]

  const fieldsUser: Field<any>[] = [
    {
      searchable: true,
      label: t('patientName'),
      //@ts-ignore
      name: 'id',
      renderFunc: (field, item) => item.patient.firstName + ' ' + item.patient.lastName,
    },
    {
      searchable: true,
      label: t('date'),
      //@ts-ignore
      name: 'date',
      sortable: true,
      renderFunc: (field, item) =>
        new Date(item.request.date)
          .toLocaleString()
          .substring(0, new Date(item.request.date).toLocaleString().length - 3) + ' h',
    },
    {
      searchable: true,
      label: t('clinicHistory'),
      //@ts-ignore
      name: 'clinicHistory',
      sortable: true,
      //@ts-ignore
      renderFunc: (field, item) => item.patient.clinicHistory,
    },
    {
      searchable: true,
      sortable: true,
      label: t('codeSample'),
      name: 'request',
      renderFunc: (field, item) => item.request.codeSample,
    },
    {
      searchable: true,
      sortable: true,
      label: t('trackingState'),
      //@ts-ignore
      name: 'trackingState',
      renderFunc: (field, item) =>
        item.client.country == 'spain' ? t(item.request.trackingState) : '',
    },
    {
      searchable: true,
      sortable: true,
      label: t('state'),
      //@ts-ignore
      name: 'state',
      renderFunc: (field, item) => t(item.request.status),
    },
    {
      searchable: true,
      sortable: true,
      label: t('bioarrayCode'),
      //@ts-ignore
      name: 'bioarrayCode',
      renderFunc: (field, item) => item.request.bioarrayCode,
    },
  ]

  const editPatient = (c2r: Client2Request) =>
    navigate(URL_APPLICATION_EDIT.replace(':id', `${c2r.request.id}`))

  const downloadReport = (c2r: Client2Request) => {
    fileService.downloadReport(c2r.request.id).subscribe((res) => {
      res && downloadFile(res.name, res.mimeType, res.base64)
    })
  }

  const downloadResults = (id: string) => {
    requestService.downloadResults(id).subscribe((f) => {
      f && downloadFile(f.name, f.mimeType, f.base64)
    })
  }

  const actions: Actions<any> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editPatient,
        icon: editIcon,
        label: t('Edit'),
        hidden: (i) =>
          !authService.userCan(Permission.editPatients) || i.request.status != 'Eraser',
      },
      {
        handler: downloadReport,
        icon: downloadIcon,
        label: t('Download'),
        hidden: (i) => i.request.status != 'Sent',
      },
      {
        handler: (i) => downloadResults(i.request.id),
        icon: seeIcon,
        label: t('downloadResult'),
        hidden: (i) => !i.request.hasResultReport || i.request.status == 'Eraser',
      },
    ],
  }

  const search: Search<RequestQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<RequestQuery>[]) => {
      svs.forEach((s) => {
        if (s.name == 'status') {
          setSelectedStatus(s.value || '')
        }
      })
      setSearcher(svs)
      setIsLoading(true)
    },
  }

  const sortable: Sort<Client2Request> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const handleCreateRecogida = (pickUp: Address) => {
    if (
      !pickUp._street ||
      !pickUp._number ||
      !pickUp._zipCode ||
      !pickUp._city ||
      !pickUp._province ||
      !pickUp._hour ||
      !pickUp._phone ||
      !pickUp._firstName ||
      !pickUp._lastName ||
      //@ts-ignore
      !pickUp._numBultos
    ) {
      return
    }

    //@ts-ignore
    pickUp._numBultos = Number(pickUp._numBultos)

    let requestsIDs = requestsSendNacex.filter((i) => i.checked)?.map((a) => a.id)

    requestService.createPickupMultiple(pickUp, requestsIDs).subscribe((res) => {
      setRequestsToSendNacex([])
      setNacexModalOpened(false)
      enqueueSnackbar(t('pickupcorrectlycreated'), { variant: 'success' })
    })
  }

  const handleOpenNacexModal = () => {
    setNacexModalOpened(true)
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {client?.country == 'spain' && (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleOpenNacexModal}
            disabled={requestsSendNacex.filter((i) => i.checked)?.length == 0}>
            {t('createPickup')}
          </Button>
        )}
      </div>
      <AppTable
        styleHeader={{ color: COLOR_APPLICATIONS }}
        actions={actions}
        //@ts-ignore
        fields={
          authService.userCan(Permission.seeRequestIDOnRequests) &&
          authService.userCan(Permission.seeClientOnRequests)
            ? fields
            : client?.country == 'spain'
            ? fieldsUser
            : fieldsUser.filter((i) => i.name != 'trackingState')
        }
        items={items}
        rowKeyField={'request'}
        pager={pager}
        search={search}
        sort={sortable}
        areRequests={true}
        handleCheck={handleCheck}
        handleCheckAll={handleCheckAll}
        requestsSendNacex={requestsSendNacex}
        allChecked={allChecked}
        selectedStatus={selectedStatus}
      />
      <Modal
        open={nacexModalOpened}
        onClose={() => setNacexModalOpened(!nacexModalOpened)}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ModalAddress
          handleModalClose={() => setNacexModalOpened(false)}
          handleCreateRecogida={handleCreateRecogida}
          pickup={address}
        />
      </Modal>
    </>
  )
}
