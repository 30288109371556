import { RouteProps } from '../../../routes/AppRouter'
import {
  Grid,
  Box,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { COLOR_PRIMARY } from '../../../routes/color-constants'
import { KeyboardDatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { PatientProduct } from 'modules/patient/models/relationship/PatientProduct'
import { useEffect, useState } from 'react'
import { PatientMicroVE } from 'modules/patient/models/patientForm/PatientMicroVE'
import { Product } from 'modules/product/models/Product'
import { useStyles } from './sampleMicroVEBioER.styles'
import { SampleType } from '../../../modules/sampleType/models/SampleType'
import { Query } from '../../../common/api/Query'
import { getSampleTypeContainer } from '../../../container/sampleType-modules'
import { SampleTypeService } from '../../../modules/sampleType/services/SampleTypeService'
import { SAMPLETYPE_SERVICE_KEY } from '../../../modules/sampleType'
import { v4 as uuidv4 } from 'uuid'
import { format, isValid } from 'date-fns'

export type SampleMicroProps = RouteProps & {
  patientProduct: PatientProduct
  handleChangePatientProducts: (pp: PatientProduct, i: number) => void
  index: number
  patients: PatientMicroVE[]
  product: Product
  edit?: boolean
}

const sampleTypeService = getSampleTypeContainer().get<SampleTypeService>(SAMPLETYPE_SERVICE_KEY)

export const SampleMicroVEBioER = (props: SampleMicroProps) => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t, i18n } = useTranslation()
  const [patientProduct, setPatientProduct] = useState<PatientProduct>(props.patientProduct)
  const [time, setTime] = useState<Date>(new Date())
  const [injectionTime, setInjectionTime] = useState<Date>(new Date())
  const [lhTime, setLhTime] = useState<Date>(new Date())
  const [p4Time, setP4Time] = useState<Date>(new Date())
  const patientNumber = props.product.patientNumber || 0
  const [sampleType, setSampleType] = useState<SampleType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    sampleTypeService.getFilteredListDB(new Query({})).subscribe((res) => {
      setSampleType(res.items)
      setIsLoading(false)
    })
  }, [])

  const handleChange = (name: string, value: any, index: number) => {
    if (props.patients) {
      let p = props.patients[index]

      switch (name) {
        case '_name':
          p.firstName = value
          p._firstName = value
          break
        case '_lastName':
          p.lastName = value
          p._lastName = value
          break
        case '_age':
          p.age = Number(value)
          p._age = Number(value)
          break
        case '_dob':
          p.dob = value
          p._dob = value
          break
        case '_email':
          p.email = value
          p._email = value
          break
        case '_fiv':
          p.ivfFailure = Number(value)
          p._ivfFailure = Number(value)
          break
        case '_abortion':
          p.abortions = Number(value)
          p._abortions = Number(value)
          break
      }

      let patients = props.patients
      patients[index] = p

      //@ts-ignore
      let newProduct = Object.assign({ ...patientProduct }, { patientMicroVE: patients })
      //@ts-ignore
      setPatientProduct(newProduct)
      //@ts-ignore
      props.handleChangePatientProducts(newProduct, props.index)
    }
  }

  const handleChangeSample = (name: string, value: any, index: number) => {
    if (props.patients) {
      let p = props.patients[index]
      let pp = props.patientProduct

      if (p && p._sample) {
        switch (name) {
          case '_type':
            if (p._sample) {
              p._sample.typeSample = value
            }

            if (p._sample) {
              p._sample._typeSample = value
            }
            break
          case 'language':
            pp.language = value
            pp._language = value
            break
          case 'endometrialBiopsyMethod':
            if (p._sample) {
              p._sample.endometrialBiopsyMethod = Number(value)
            }

            if (p.sample) {
              p.sample._endometrialBiopsyMethod = Number(value)
            }
            break
          case '_size':
            if (p._sample) {
              p._sample.size = Number(value)
            }

            if (p.sample) {
              p.sample._size = Number(value)
            }
            break
          case '_biopsyDate':
            p._sample.biopsyDate = value
            p._sample.biopsyDate.setHours(time.getHours())
            p._sample.biopsyDate.setMinutes(time.getMinutes())

            p._sample._biopsyDate = value
            p._sample._biopsyDate.setHours(time.getHours())
            p._sample._biopsyDate.setMinutes(time.getMinutes())
            break
          case '_biopsyTime':
            setTime(value)
            p._sample.biopsyDate.setHours(value.getHours())
            p._sample.biopsyDate.setMinutes(value.getMinutes())
            p._sample.biopsyTime = value

            p._sample._biopsyDate.setHours(value.getHours())
            p._sample._biopsyDate.setMinutes(value.getMinutes())
            p._sample._biopsyTime = value
            break
          case '_biopsyNumber':
            p._sample.biopsyNumber = Number(value)
            p._sample._biopsyNumber = Number(value)
            break
          case '_pCycle':
            p._sample.pCycle = Number(value)
            p._sample._pCycle = Number(value)
            break
          case '_p4DateTime':
            p._sample.p4DateTime = value
            p._sample._p4DateTime = value

            p._sample.p4DateTime.setHours(p4Time.getHours())
            p._sample.p4DateTime.setMinutes(p4Time.getMinutes())

            p._sample._p4DateTime.setHours(p4Time.getHours())
            p._sample._p4DateTime.setMinutes(p4Time.getMinutes())
            break
          case '_lhCycle':
            p._sample.lhCycle = Number(value)
            p._sample._lhCycle = Number(value)
            break
          case '_lhDateTime':
            p._sample.lhDateTime = value
            p._sample._lhDateTime = value

            p._sample.lhDateTime.setHours(lhTime.getHours())
            p._sample.lhDateTime.setMinutes(lhTime.getMinutes())

            p._sample._lhDateTime.setHours(lhTime.getHours())
            p._sample._lhDateTime.setMinutes(lhTime.getMinutes())
            break
          case '_hcgCycle':
            p._sample.hcgCycle = Number(value)
            p._sample._hcgCycle = Number(value)
            break
          case '_hcgInjection':
            p._sample.hcgInjection = value
            p._sample.hcgInjection.setHours(injectionTime.getHours())
            p._sample.hcgInjection.setMinutes(injectionTime.getMinutes())

            p._sample._hcgInjection = value
            p._sample._hcgInjection.setHours(injectionTime.getHours())
            p._sample._hcgInjection.setMinutes(injectionTime.getMinutes())
            break
          case '_hcgInjectionTime':
            setInjectionTime(value)
            p._sample.hcgInjection.setHours(value.getHours())
            p._sample.hcgInjection.setMinutes(value.getMinutes())

            p._sample._hcgInjection.setHours(value.getHours())
            p._sample._hcgInjection.setMinutes(value.getMinutes())
            break
          case '_p4Time':
            setP4Time(value)

            p._sample.p4DateTime.setHours(value.getHours())
            p._sample.p4DateTime.setMinutes(value.getMinutes())

            p._sample._p4DateTime.setHours(value.getHours())
            p._sample._p4DateTime.setMinutes(value.getMinutes())
            break
          case '_lhTime':
            setLhTime(time)
            p._sample.lhDateTime.setHours(value.getHours())
            p._sample.lhDateTime.setMinutes(value.getMinutes())

            p._sample._lhDateTime.setHours(value.getHours())
            p._sample._lhDateTime.setMinutes(value.getMinutes())
            break
          case '_hrt':
            p._sample.hrt = value
            p._sample._hrt = value
            break
          case '_natural':
            p._sample.natural = value
            p._sample._natural = value
            break
          case '_question1':
            p._sample.question1 = value
            p._sample._question1 = value
            break
          case '_typeAntibioticTreatment':
            p._sample.typeAntibioticTreatment = value
            p._sample._typeAntibioticTreatment = value
            break
          case '_treatmentDuration':
            p._sample.treatmentDuration = value
            p._sample._treatmentDuration = value
            break
          case '_endDate':
            p._sample.endDate = value
            p._sample._endDate = value
            break
          case '_question2':
            p._sample.question2 = value
            p._sample._question2 = value
            break
          case '_reportDate':
            p._sample.reportDate = value
            p._sample._reportDate = value
            break
          case '_result':
            p._sample.result = value
            p._sample._result = value
            break
          case '_followedTreatment':
            p._sample.followedTreatment = value
            p._sample._followedTreatment = value
            break
          case '_symptoms':
            p._sample.symptoms = value
            p._sample._symptoms = value
            break
          case '_question3':
            p._sample.question3 = value
            p._sample._question3 = value
            break
          case '_code':
            p._sample.code = value
            p._sample._code = value
            break
        }
      } else {
        p = props.patientProduct.patientMicroVE[index]
        switch (name) {
          case '_type':
            if (p._sample) {
              p._sample.typeSample = value
            }

            if (p._sample) {
              p._sample._typeSample = value
            }
            break
          case 'language':
            pp.language = value
            pp._language = value
            break
          case 'endometrialBiopsyMethod':
            if (p._sample) {
              p._sample.endometrialBiopsyMethod = Number(value)
            }

            if (p.sample) {
              p.sample._endometrialBiopsyMethod = Number(value)
            }
            break
          case '_size':
            if (p._sample) {
              p._sample.size = Number(value)
            }

            if (p.sample) {
              p.sample._size = Number(value)
            }
            break
          case '_biopsyDate':
            p._sample.biopsyDate = value
            p._sample.biopsyDate.setHours(time.getHours())
            p._sample.biopsyDate.setMinutes(time.getMinutes())

            p._sample._biopsyDate = value
            p._sample._biopsyDate.setHours(time.getHours())
            p._sample._biopsyDate.setMinutes(time.getMinutes())
            break
          case '_biopsyTime':
            setTime(value)
            p._sample.biopsyDate.setHours(value.getHours())
            p._sample.biopsyDate.setMinutes(value.getMinutes())
            p._sample.biopsyTime = value

            p._sample._biopsyDate.setHours(value.getHours())
            p._sample._biopsyDate.setMinutes(value.getMinutes())
            p._sample._biopsyTime = value
            break
          case '_biopsyNumber':
            p._sample.biopsyNumber = Number(value)
            p._sample._biopsyNumber = Number(value)
            break
          case '_pCycle':
            p._sample.pCycle = Number(value)
            p._sample._pCycle = Number(value)
            break
          case '_p4DateTime':
            p._sample.p4DateTime = value
            p._sample._p4DateTime = value

            p._sample.p4DateTime.setHours(p4Time.getHours())
            p._sample.p4DateTime.setMinutes(p4Time.getMinutes())

            p._sample._p4DateTime.setHours(p4Time.getHours())
            p._sample._p4DateTime.setMinutes(p4Time.getMinutes())
            break
          case '_lhCycle':
            p._sample.lhCycle = Number(value)
            p._sample._lhCycle = Number(value)
            break
          case '_lhDateTime':
            p._sample.lhDateTime = value
            p._sample._lhDateTime = value

            p._sample.lhDateTime.setHours(lhTime.getHours())
            p._sample.lhDateTime.setMinutes(lhTime.getMinutes())

            p._sample._lhDateTime.setHours(lhTime.getHours())
            p._sample._lhDateTime.setMinutes(lhTime.getMinutes())
            break
          case '_hcgCycle':
            p._sample.hcgCycle = Number(value)
            p._sample._hcgCycle = Number(value)
            break
          case '_hcgInjection':
            p._sample.hcgInjection = value
            p._sample.hcgInjection.setHours(injectionTime.getHours())
            p._sample.hcgInjection.setMinutes(injectionTime.getMinutes())

            p._sample._hcgInjection = value
            p._sample._hcgInjection.setHours(injectionTime.getHours())
            p._sample._hcgInjection.setMinutes(injectionTime.getMinutes())
            break
          case '_hcgInjectionTime':
            setInjectionTime(value)
            p._sample.hcgInjection.setHours(value.getHours())
            p._sample.hcgInjection.setMinutes(value.getMinutes())

            p._sample._hcgInjection.setHours(value.getHours())
            p._sample._hcgInjection.setMinutes(value.getMinutes())
            break
          case '_p4Time':
            setP4Time(value)

            p._sample.p4DateTime.setHours(value.getHours())
            p._sample.p4DateTime.setMinutes(value.getMinutes())

            p._sample._p4DateTime.setHours(value.getHours())
            p._sample._p4DateTime.setMinutes(value.getMinutes())
            break
          case '_lhTime':
            setLhTime(time)
            p._sample.lhDateTime.setHours(value.getHours())
            p._sample.lhDateTime.setMinutes(value.getMinutes())

            p._sample._lhDateTime.setHours(value.getHours())
            p._sample._lhDateTime.setMinutes(value.getMinutes())
            break
          case '_hrt':
            p._sample.hrt = value
            p._sample._hrt = value
            break
          case '_natural':
            p._sample.natural = value
            p._sample._natural = value
            break
          case '_question1':
            p._sample.question1 = value
            p._sample._question1 = value
            break
          case '_typeAntibioticTreatment':
            p._sample.typeAntibioticTreatment = value
            p._sample._typeAntibioticTreatment = value
            break
          case '_treatmentDuration':
            p._sample.treatmentDuration = value
            p._sample._treatmentDuration = value
            break
          case '_endDate':
            p._sample.endDate = value
            p._sample._endDate = value
            break
          case '_question2':
            p._sample.question2 = value
            p._sample._question2 = value
            break
          case '_reportDate':
            p._sample.reportDate = value
            p._sample._reportDate = value
            break
          case '_result':
            p._sample.result = value
            p._sample._result = value
            break
          case '_followedTreatment':
            p._sample.followedTreatment = value
            p._sample._followedTreatment = value
            break
          case '_symptoms':
            p._sample.symptoms = value
            p._sample._symptoms = value
            break
          case '_question3':
            p._sample.question3 = value
            p._sample._question3 = value
            break
          case '_code':
            p._sample.code = value
            p._sample._code = value
            break
        }
      }

      let patients = props.patients
      patients[index] = p

      //@ts-ignore
      let newProduct = Object.assign({ ...patientProduct }, { patientMicroVE: patients })
      //@ts-ignore
      setPatientProduct(newProduct)
      //@ts-ignore
      props.handleChangePatientProducts(newProduct, props.index)
    }
  }

  const handleChangeGynecological = (type: string, index: number) => {
    if (type == '_abortion') {
      if (patientProduct.patientMicroVE[index]._abortions > 0) {
        handleChange('_abortion', 0, index)
      } else {
        handleChange('_abortion', 1, index)
      }
    } else if (type == '_fiv') {
      if (patientProduct.patientMicroVE[index]._ivfFailure > 0) {
        handleChange('_fiv', 0, index)
      } else {
        handleChange('_fiv', 1, index)
      }
    }
  }

  const formatDate = (date: Date) =>{
    const sdate: string = format(date, "ddMMyyyy")
    const d = parseInt(sdate.slice(0,2))
    const m = parseInt(sdate.slice(2,4))-1
    const y = parseInt(sdate.slice(4,8))

    return new Date (Date.UTC(y,m,d))
  }

  const getQuestion1 = (index: number) => {
    return (
      <>
        {patientProduct.patientMicroVE[index]?._sample?._question1 && (
          <>
            <Grid container spacing={4} key={index} className={classes.gynecologicalConditionGrid}>
              <Grid item xs={4} className={classes.grid}>
                <TextField
                  id="code"
                  variant="filled"
                  disabled={props.edit == false}
                  className={classes.textField}
                  fullWidth
                  value={patientProduct.patientMicroVE[index]._sample._typeAntibioticTreatment}
                  onChange={(e) =>
                    handleChangeSample('_typeAntibioticTreatment', e.target.value, index)
                  }
                  placeholder={t('antibioticType') + '*'}
                  required
                />
              </Grid>
            </Grid>

            <Grid container spacing={4} key={index} className={classes.gynecologicalConditionGrid}>
              <Grid item xs={6} className={classes.grid}>
                <p>{t('treatmentDuration') + '*'}: </p>
                <TextField
                  id="code"
                  disabled={props.edit == false}
                  value={patientProduct.patientMicroVE[index]._sample._treatmentDuration}
                  onChange={(e) => handleChangeSample('_treatmentDuration', e.target.value, index)}
                  type="number"
                  className={classes.treatmentDurationTextField}
                  required
                />
              </Grid>
              <Grid item xs={6} className={classes.grid}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    inputVariant={'outlined'}
                    disabled={props.edit == false}
                    format="dd/MM/yyyy"
                    value={patientProduct.patientMicroVE[index]._sample._endDate}
                    onChange={(date) =>
                      date && isValid(date) && handleChangeSample('_endDate', formatDate(date), index)
                    }
                    required={true}
                    size={'small'}
                    label={t('treatCompletionDate')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  }

  const getQuestion2 = (index: number) => {
    return (
      <>
        {patientProduct.patientMicroVE[index]._sample?._question2 && (
          <>
            <Grid container spacing={4} key={index} className={classes.gynecologicalConditionGrid}>
              <Grid item xs={6} className={classes.grid}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    inputVariant={'outlined'}
                    format="dd/MM/yyyy"
                    value={patientProduct.patientMicroVE[index]._sample._reportDate}
                    onChange={(date) =>
                      date && isValid(date) && handleChangeSample('_reportDate', formatDate(date), index)
                    }
                    required={true}
                    size={'small'}
                    label={t('reportDate')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Grid container spacing={4} key={index} className={classes.top5}>
              <Grid item xs={6}>
                <TextField
                  id="code"
                  variant="filled"
                  className={classes.textField}
                  fullWidth
                  value={patientProduct.patientMicroVE[index]._sample._result}
                  onChange={(e) => handleChangeSample('_result', e.target.value, index)}
                  placeholder={t('result') + '*'}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="code"
                  variant="filled"
                  className={classes.textField}
                  fullWidth
                  value={patientProduct.patientMicroVE[index]._sample._followedTreatment}
                  onChange={(e) => handleChangeSample('_followedTreatment', e.target.value, index)}
                  placeholder={t('followedTreatment') + '*'}
                  type="email"
                  required
                />
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  }

  const getQuestion3 = (index: number) => {
    return (
      <>
        {patientProduct.patientMicroVE[index]._sample?._question3 && (
          <>
            <Grid container spacing={4} key={index} className={classes.top5}>
              <Grid item xs={6}>
                <TextField
                  id="code"
                  variant="filled"
                  className={classes.textField}
                  fullWidth
                  value={patientProduct.patientMicroVE[index]._sample._symptoms}
                  onChange={(e) => handleChangeSample('_symptoms', e.target.value, index)}
                  placeholder={t('symptoms')}
                />
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  }

  return (
    <>
      {!isLoading ? (
        <Grid container direction="column" className={classes.generalGrid}>
          {[...Array(patientNumber)].map((_, index) => (
            <>
              <Grid container spacing={4} key={index} className={classes.pInformationGrid}>
                <Grid item xs={12} className={classes.titleText}>
                  {t('dateAboutSample') +
                    ' ' +
                    (index + 1) +
                    ' ' +
                    t('for') +
                    ' ' +
                    (i18n.language ==
                  'es'
                    ? props.product?.nameES
                    : props.product?.nameEN)}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={4}
                key={index}
                className={classes.gynecologicalConditionGrid}>
                <Grid item xs={12} className={classes.gynecologicalConditionText}>
                  {t('gynecologicalCondition')}
                </Grid>

                <Grid item xs={8} className={classes.grid}>
                  <Checkbox
                    checked={patientProduct.patientMicroVE[index]._ivfFailure > 0}
                    onChange={() => handleChangeGynecological('_fiv', index)}
                    className={classes.padding1}
                    color="primary"
                  />
                  {t('fivFail')}: {t('ciclesNumber')}:
                </Grid>
                <Grid item xs={4} className={classes.grid2}>
                  <TextField
                    id="code"
                    value={patientProduct.patientMicroVE[index]._ivfFailure}
                    onChange={(e) =>
                      Number(e.target.value) >= 0 && handleChange('_fiv', e.target.value, index)
                    }
                    type="number"
                    inputProps={{ min: 0 }}
                    className={classes.width40}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4} key={index} className={classes.miscarriagesGrid}>
                <Grid item xs={8} className={classes.grid}>
                  <Checkbox
                    checked={patientProduct.patientMicroVE[index]._abortions > 0}
                    onChange={() => handleChangeGynecological('_abortion', index)}
                    className={classes.padding1}
                    color="primary"
                  />
                  {t('miscarriages')}: {t('miscarriagesNumber')}:
                </Grid>

                <Grid item xs={4} className={classes.grid2}>
                  <TextField
                    id="code"
                    value={patientProduct.patientMicroVE[index]._abortions}
                    onChange={(e) =>
                      Number(e.target.value) >= 0 &&
                      handleChange('_abortion', e.target.value, index)
                    }
                    type="number"
                    inputProps={{ min: 0 }}
                    className={classes.width40}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4} key={index} style={{ marginTop: '2.5%' }}>
                <Grid item xs={12} className={classes.text}>
                  {t('sampleInformation')}
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="typeSampleLabel">{t('typeSample') + '*'}</InputLabel>
                    <Select
                      labelId={'typeSampleLabel'}
                      id={'type'}
                      name={'type'}
                      required
                      onChange={(e) => handleChangeSample('_type', e.target.value, index)}
                      value={patientProduct.patientMicroVE[index]?._sample?._typeSample}>
                      {sampleType.map((elementST, indexST) => {
                        return (
                          <MenuItem key={indexST} value={elementST.idOdoo ? elementST.idOdoo : ''}>
                            {(i18n.language == 'es' ? elementST?.nameES : elementST?.nameEN)}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="code"
                    variant="filled"
                    label={t('codeSample')}
                    className={classes.textField}
                    disabled={props.edit == false}
                    fullWidth
                    value={
                      patientProduct.patientMicroVE &&
                      patientProduct.patientMicroVE[index]?._sample?._code
                    }
                    onChange={(e) => handleChangeSample('_code', e.target.value, index)}
                  />
                </Grid>
              </Grid>

              <Box className={classes.endometrialBiopsyMethod}>
                <Box className={classes.endometrialBiopsyMethod2}>{t('ebMethod')}:</Box>
                <Box className={classes.endometrialBiopsyMethod2}>
                  <Checkbox
                    checked={
                      patientProduct.patientMicroVE[index]?._sample?._endometrialBiopsyMethod === 0
                    }
                    onChange={(e) => handleChangeSample('endometrialBiopsyMethod', 0, index)}
                    color="primary"
                    key={uuidv4()}
                  />
                  <p>{t('pipette')}</p>
                </Box>
                <Box className={classes.endometrialBiopsyMethod2}>
                  <Checkbox
                    checked={
                      patientProduct.patientMicroVE[index]?._sample?._endometrialBiopsyMethod == 1
                    }
                    onChange={(e) => handleChangeSample('endometrialBiopsyMethod', 1, index)}
                    color="primary"
                    key={uuidv4()}
                  />
                  <p>{t('legra')}</p>
                </Box>
                <Box className={classes.endometrialBiopsyMethod3}>
                  <Checkbox
                    checked={
                      patientProduct.patientMicroVE[index]?._sample?._endometrialBiopsyMethod == 2
                    }
                    onChange={(e) => handleChangeSample('endometrialBiopsyMethod', 2, index)}
                    color="primary"
                    key={uuidv4()}
                  />
                  <p>{t('histeroscopy')}</p>
                </Box>
              </Box>

              <Grid container spacing={4} key={index} className={classes.sizeGrid}>
                <Grid item xs={6} className={classes.grid}>
                  <p style={{ marginRight: '0.8%' }}>{t('size')}:</p>
                  <TextField
                    id="code"
                    value={patientProduct.patientMicroVE[index]?._sample?._size}
                    onChange={(e) => handleChangeSample('_size', e.target.value, index)}
                    type="number"
                    className={classes.sizeTextField}
                  />
                  <p>mm</p>
                </Grid>
              </Grid>

              <Grid container spacing={4} key={index} className={classes.sizeGrid}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.width100}
                      autoOk
                      inputVariant={'outlined'}
                      format="dd/MM/yyyy"
                      value={patientProduct.patientMicroVE[index]?._sample?._biopsyDate}
                      onChange={(date) =>
                        date && isValid(date) && handleChangeSample('_biopsyDate', formatDate(date), index)
                      }
                      required={true}
                      size={'small'}
                      label={t('dobiopsy')}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      value={patientProduct.patientMicroVE[index]?._sample?._biopsyDate}
                      label={t('biopsyHour')}
                      onChange={(date) =>
                        date && handleChangeSample('_biopsyTime', new Date(date), index)
                      }
                      className={classes.timePicker}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>

              <Box className={classes.endometrialBiopsyMethod}>
                <Box className={classes.endometrialBiopsyMethod2}>
                  <Checkbox
                    checked={patientProduct.patientMicroVE[index]?._sample?._biopsyNumber == 1}
                    onChange={(date) => handleChangeSample('_biopsyNumber', 1, index)}
                    color="primary"
                  />
                  <p>1ª {t('biopsy')}</p>
                </Box>
                <Box className={classes.endometrialBiopsyMethod2}>
                  <Checkbox
                    checked={patientProduct.patientMicroVE[index]?._sample?._biopsyNumber == 2}
                    onChange={(date) => handleChangeSample('_biopsyNumber', 2, index)}
                    color="primary"
                  />
                  <p>2ª {t('biopsy')}</p>
                </Box>
              </Box>

              <Grid container spacing={4} key={index} className={classes.sizeGrid}>
                <Grid item xs={12} className={classes.text}>
                  {t('cycleTypeDate')}:
                </Grid>
                <Grid item xs={12} className={classes.grid3}>
                  <Grid className={classes.grid20}>
                    <Checkbox
                      checked={patientProduct.patientMicroVE[index]?._sample?._hrt}
                      onChange={(date) =>
                        handleChangeSample(
                          '_hrt',
                          !patientProduct.patientMicroVE[index]?.sample?.hrt,
                          index
                        )
                      }
                      color="primary"
                    />
                    <p>HRT: </p>
                    <p style={{ marginLeft: '1%', marginRight: '3%' }}>P+ </p>
                    <TextField
                      id="code"
                      value={patientProduct.patientMicroVE[index]?._sample?._pCycle}
                      onChange={(e) => handleChangeSample('_pCycle', e.target.value, index)}
                      type="number"
                      style={{ width: '50px', marginTop: '1.5%' }}
                    />
                  </Grid>
                  <p className={classes.width15}>{t('p4FirstDose')}:</p>
                  <p style={{ marginRight: '2%' }}>{t('date')}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      inputVariant={'outlined'}
                      format="dd/MM/yyyy"
                      value={patientProduct.patientMicroVE[index]?._sample?._p4DateTime}
                      onChange={(date) =>
                        date && isValid(date) && handleChangeSample('_p4DateTime', formatDate(date), index)
                      }
                      required={true}
                      size={'small'}
                      style={{ marginTop: '0.5%' }}
                    />
                  </MuiPickersUtilsProvider>
                  <p style={{ marginLeft: '2.5%', marginRight: '1%' }}>{t('hour')}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      value={patientProduct.patientMicroVE[index]?._sample?._p4DateTime}
                      onChange={(date) =>
                        date && handleChangeSample('_p4Time', new Date(date), index)
                      }
                      style={{ marginTop: '1%', marginLeft: '1%' }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} className={classes.grid3}>
                  <Grid className={classes.grid20}>
                    <Checkbox
                      checked={patientProduct.patientMicroVE[index]?._sample?._natural}
                      onChange={(date) =>
                        handleChangeSample(
                          '_natural',
                          !patientProduct.patientMicroVE[index]?.sample?.natural,
                          index
                        )
                      }
                      color="primary"
                    />
                    <p>Natural: </p>
                    <p style={{ marginLeft: '1%', marginRight: '3%' }}>LH+ </p>
                    <TextField
                      id="code"
                      value={patientProduct.patientMicroVE[index]?._sample?._lhCycle}
                      onChange={(e) => handleChangeSample('_lhCycle', e.target.value, index)}
                      type="number"
                      style={{ width: '50px', marginTop: '1%' }}
                    />
                  </Grid>

                  <p className={classes.width15}>{t('lhPeak')}:</p>
                  <p style={{ marginRight: '2%' }}>{t('date')}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      inputVariant={'outlined'}
                      format="dd/MM/yyyy"
                      value={patientProduct.patientMicroVE[index]?._sample?._lhDateTime}
                      onChange={(date) =>
                        date && isValid(date) && handleChangeSample('_lhDateTime', formatDate(date), index)
                      }
                      required={true}
                      size={'small'}
                      style={{ marginTop: '0.5%' }}
                    />
                  </MuiPickersUtilsProvider>
                  <p style={{ marginLeft: '2.5%', marginRight: '1%' }}>{t('hour')}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      value={patientProduct.patientMicroVE[index]?._sample?._lhDateTime}
                      onChange={(date) =>
                        date && handleChangeSample('_lhTime', new Date(date), index)
                      }
                      style={{ marginTop: '1%', marginLeft: '1%' }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} className={classes.grid3}>
                  <Grid className={classes.grid20}>
                    <p style={{ marginLeft: '3.5%' }}>hCG+ </p>
                    <TextField
                      id="code"
                      value={patientProduct.patientMicroVE[index]?._sample?._hcgCycle}
                      onChange={(e) => handleChangeSample('_hcgCycle', e.target.value, index)}
                      type="number"
                      className={classes.textFieldCycle}
                    />
                  </Grid>
                  <p className={classes.width15}>{t('hCGInjection')}:</p>
                  <p style={{ marginRight: '2%' }}>{t('date')}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      inputVariant={'outlined'}
                      format="dd/MM/yyyy"
                      value={patientProduct.patientMicroVE[index]?._sample?._hcgInjection}
                      onChange={(date) =>
                        date && isValid(date) && handleChangeSample('_hcgInjection', formatDate(date), index)
                      }
                      required={true}
                      size={'small'}
                      style={{ marginTop: '0.5%' }}
                    />
                  </MuiPickersUtilsProvider>
                  <p style={{ marginLeft: '2.5%', marginRight: '1%' }}>{t('hour')}</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      value={patientProduct.patientMicroVE[index]?._sample?._hcgInjection}
                      onChange={(date) =>
                        date && handleChangeSample('_hcgInjectionTime', new Date(date), index)
                      }
                      style={{ marginTop: '1%', marginLeft: '1%' }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} className={classes.desc1}>
                  * {t('firstProgesteroneDay')}
                </Grid>
                <Grid item xs={12} className={classes.desc2}>
                  * {t('lhPeakDescription')}
                </Grid>
                <Grid item xs={12} className={classes.desc2}>
                  * {t('hCGInjectionDescription')}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={4}
                key={index}
                className={classes.gynecologicalConditionGrid}>
                <Grid item xs={12} className={classes.gynecologicalConditionText}>
                  {t('antibioticTreatmentQuesiton')}
                </Grid>

                <Grid item xs={8} className={classes.grid}>
                  <Grid>
                    <Checkbox
                      checked={patientProduct.patientMicroVE[index]?._sample?._question1 == true}
                      onChange={() => handleChangeSample('_question1', true, index)}
                      className={classes.padding1}
                      color="primary"
                    />
                    {t('yes')}
                  </Grid>
                  <Grid className={classes.left4}>
                    <Checkbox
                      checked={
                        patientProduct.patientMicroVE[index]?._sample?._question1 == false ||
                        patientProduct.patientMicroVE[index]?._sample?._question1 == undefined
                      }
                      onChange={() => handleChangeSample('_question1', false, index)}
                      className={classes.padding1}
                      color="primary"
                    />
                    {t('no')}
                  </Grid>
                </Grid>
              </Grid>

              {getQuestion1(index)}

              <Grid
                container
                spacing={4}
                key={index}
                className={classes.gynecologicalConditionGrid}>
                <Grid item xs={12} className={classes.gynecologicalConditionText}>
                  {t('microbiomeAnalysisQuestion')}
                </Grid>
                <Grid item xs={8} className={classes.grid}>
                  <Grid>
                    <Checkbox
                      checked={patientProduct.patientMicroVE[index]?._sample?._question2}
                      onChange={() => handleChangeSample('_question2', true, index)}
                      className={classes.padding1}
                      color="primary"
                    />
                    {t('yes')}
                  </Grid>
                  <Grid className={classes.left4}>
                    <Checkbox
                      checked={!patientProduct.patientMicroVE[index]?._sample?._question2}
                      onChange={() => handleChangeSample('_question2', false, index)}
                      className={classes.padding1}
                      color="primary"
                    />
                    {t('no')}
                  </Grid>
                </Grid>
              </Grid>

              {getQuestion2(index)}

              <Grid
                container
                spacing={4}
                key={index}
                className={classes.gynecologicalConditionGrid}>
                <Grid item xs={12} className={classes.gynecologicalConditionText}>
                  {t('endometriosisQuestion')}
                </Grid>

                <Grid item xs={8} className={classes.grid}>
                  <Grid>
                    <Checkbox
                      checked={patientProduct.patientMicroVE[index]?._sample?._question3}
                      onChange={() => handleChangeSample('_question3', true, index)}
                      className={classes.padding1}
                      color="primary"
                    />
                    {t('yes')}
                  </Grid>
                  <Grid className={classes.left4}>
                    <Checkbox
                      checked={!patientProduct.patientMicroVE[index]?._sample?._question3}
                      onChange={() => handleChangeSample('_question3', false, index)}
                      className={classes.padding1}
                      color="primary"
                    />
                    {t('no')}
                  </Grid>
                </Grid>
              </Grid>
              {getQuestion3(index)}
            </>
          ))}
        </Grid>
      ) : (
        <CircularProgress style={{ alignSelf: 'center' }} />
      )}
    </>
  )
}
